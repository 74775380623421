import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductsPage from "../pages/products"


const IndexPage = ({ data }) => (
  <Layout>
    <div className="our-story-relative">
      <div className="main-image">
      <StaticImage 
        src="../assets/images/fullCookieTray.jpg"
        width={2000}
        height={600}
      />
      </div>
      <div className="home-intro">
        <h1 className="welcome">Hamby Artisan Bakery</h1>
        <h2>Baked from scratch, in small batches, from our home to yours.</h2>
        <p className="welcome-par">
          We are a small, family-owned bakery located in Thompson's Station, TN that specializes in handcrafted, artisan cookies, cakes, and other treats. 
          <br/>
          <br />
          All of our ingredients are sourced locally and are organic when possible (99% of the time!), and we do not use any artifical colors, flavors, or seed oils. 
          <br />
          <br />
          Currently, we offer pickup in Thompson's Station or local delivery in Williamson county. 
          <br />
          <br />
          <b>Orders must be placed at least 48 hours in advance. Before checking out, please select a delivery date from the date field on the product page, and include any other information in the 'Order special instructions' box located in your cart. Thanks!</b>
        </p>
      </div>
      <ProductsPage data={data}/>
    </div>
  </Layout>
)
export const Head = () => <Seo title="Home" />

export default IndexPage

export const query = graphql`
{
  allShopifyProduct(
    sort: {fields: [title]}
    filter: {collections: {elemMatch: {title: {eq: "Home page"}}}}
  ) {
    edges {
      node {
        title
        handle
        shopifyId
        description
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        status
        media {
          id
        }
        featuredImage {
          src
        }
      }
    }
  }
}
`