import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"

const ProductsPage = ({ data }) => (
      <>
    <div>
        <h1>Featured Products</h1>
    </div>
    {data &&
    <div className="feature-products-area">
    <ul className="feature-products">
      {data.allShopifyProduct.edges.map(({ node }) => (
        <li key={node.shopifyId} className="feature-product">
          <Link to={`https://hamby-artisan-bakery.myshopify.com/products/${node.handle}`}>
            <img src={node.featuredImage.src} className="feature-product-img" alt="featured-product"/>
          </Link>
          <h3 className="feature-product-title">
            <Link to={`https://hamby-artisan-bakery.myshopify.com/products/${node.handle}`}>{node.title}</Link>
            {" - "}${node.priceRangeV2.minVariantPrice.amount}
          </h3>
        </li>
      ))}
    </ul>
    </div>
    }
    </>
)

export const Head = () => <Seo title="Products" />

export default ProductsPage